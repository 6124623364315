import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//import Slide from "@mui/material/Slide";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function AlertDialogSlide(props) {
  const [open, setOpen] = React.useState(props?.open);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Dialog
        open={open}
        //TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          props?.onClose();
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props?.onClose();
            }}
          >
            Close
          </Button>
          {props?.type === "confirm" && (
            <Button
              onClick={() => {
                props?.onConfirm();
              }}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
