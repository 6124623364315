import { configureStore } from "@reduxjs/toolkit";
import formDataReducer from "./slices/formData";
import clusterReducer from "./slices/cluster";
import clusterProgress from "./slices/clusterProgress";
import clusterStatistics from "./slices/clusterStatistics";
import storageStatistics from "./slices/storageStatistics";
import registryReducer from "./slices/registry";
import computerReducer from "./slices/computer";
import computerProgress from "./slices/computerProgress";
import bitnamiReducer from "./slices/bitnami";

export const store = configureStore({
  reducer: {
    formData: formDataReducer,
    cluster: clusterReducer,
    clusterProgress: clusterProgress,
    clusterStatistics: clusterStatistics,
    computer: computerReducer,
    computerProgress: computerProgress,
    registry: registryReducer,
    storageStatistics: storageStatistics,
    bitnami: bitnamiReducer,
  },
});
