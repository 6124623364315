import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Box, Paper, Typography } from "@mui/material";

import ForgetPasswordComponent from "../../components/auth/ForgetPassword";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ForgetPassword() {
  return (
    <React.Fragment>
      <Helmet title="Forget password" />
      <img
        src="/static/img/brands/logo.svg"
        width={220}
        height={64}
        alt="logo-opensacler"
        style={{ marginBottom: 32 }}
      />{" "}
      <Wrapper>
        <Helmet title="Reset Password" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Forget Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography>

        <ForgetPasswordComponent />
      </Wrapper>
      <Box sx={{ position: "absolute", bottom: 16 }}>
        <Typography mx={4}>
          © 2022 - OpenScaler Cloud Computer Technology Platform ** MVP **
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default ForgetPassword;
