import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  statistics: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: "storageStatistics",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setStorageStatistics(state, { payload }) {
      state.statistics = [...payload];
      state.isLoading = false;
    },
  },
});

export function getStorageStatistics(id, type) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/charts-storage-${type}`
      );
      dispatch(slice.actions.setStorageStatistics(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
