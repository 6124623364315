import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Search as SearchIcon } from "react-feather";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Card as MuiCard,
  CardActions,
  Button,
  Chip as MuiChip,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  TextField as MuiTextField,
  InputAdornment,
  Pagination,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getBitnami } from "../../redux/slices/bitnami";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const TextField = styled(MuiTextField)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const Search = (props) => {
  const [value, setValue] = useState("");
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <TextField
        size="small"
        id="searchProduct"
        name="searchProduct"
        placeholder="Search"
        variant="outlined"
        my={2}
        sx={{ width: "40%" }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <div style={{ cursor: "pointer" }} title="Search">
              <InputAdornment position="end">
                <SearchIcon
                  onClick={() => {
                    props.onChange(value);
                  }}
                />
              </InputAdornment>
            </div>
          ),
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.onChange(value);
          }
        }}
      />
    </div>
  );
};

const Item = (props) => {
  return (
    <Card>
      <CardContent>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          <img
            src={props.data.icon}
            width={68}
            height={68}
            alt={props.data.name}
          />
          <div>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "1.8em",
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                style={{ textTransform: "capitalize" }}
                ml={4}
                mb={0}
              >
                {props.data.name}
              </Typography>
            </div>
            <Typography gutterBottom variant="body1" ml={6}>
              Ver <b>{props.data.version}</b>
            </Typography>
            {props.data?.annotations?.category ? (
              <Chip
                label={props.data.annotations.category}
                color="success"
                sx={{ ml: 6 }}
              />
            ) : (
              <Chip label={"Not specified"} color="warning" sx={{ ml: 6 }} />
            )}
          </div>
        </div>

        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "1.5em",
            height: "3em",
            marginBottom: 10,
          }}
        >
          <Typography mb={4} color="textSecondary" variant="body1">
            {props.data.description}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" variant="outlined" p={0}>
          DEPLOY
        </Button>
        <Button size="small" color="primary">
          More Details
        </Button>
      </CardActions>
    </Card>
  );
};

function Public() {
  const dispatch = useDispatch();
  const { bitnami, isLoading } = useSelector((state) => state.bitnami);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  useEffect(() => {
    dispatch(getBitnami(search));
  }, [dispatch, search]);

  return (
    <Grid container spacing={4} mb={6}>
      <Grid item xs={12}>
        <Search
          onChange={(value) => {
            setCurrentPage(1);
            setSearch(value);
          }}
        />
      </Grid>
      {!isLoading &&
        bitnami
          .slice((currentPage - 1) * pageSize, currentPage * pageSize)
          .map((item, index) => (
            <Grid key={index} item xs={12} lg={6} xl={3}>
              <Item data={item} />
            </Grid>
          ))}
      <Grid item xs={12} mt={8}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={Math.ceil(bitnami.length / pageSize)}
            variant="outlined"
            page={currentPage}
            onChange={(e, value) => {
              setCurrentPage(value);
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

function Marketplace() {
  return (
    <React.Fragment>
      <Helmet title="Marketplace" />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Public />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Marketplace;
