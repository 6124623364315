import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  clusterProgress: [],
  progressStep: 0,
  progressEnd: false,
  clusterLogs: {},
  isLoading: false,
  error: null,
};

const progressRows = [
  {
    task: "OS templates prep",
    resourceId: "VM/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "Storage provisioning",
    resourceId: "VM/Storage",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "VPC Network",
    resourceId: "VM/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "OS templates prep",
    resourceId: "VM/Storage",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "NAT Gateway & SNAT",
    resourceId: "VM/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "Server Load Blancer",
    resourceId: "VM/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "TCP/IP Protocol",
    resourceId: "VM/Storage",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "HTTP Protocol",
    resourceId: "VM/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "Server Load Blancer",
    resourceId: "VM/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "TCP/IP Protocol",
    resourceId: "VM/Storage",
    startedAt: null,
    endedAt: null,
  },
];

const slice = createSlice({
  name: "clusterProgress",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setProgressStep(state, { payload }) {
      state.progressStep = payload;
    },
    setProgressEnd(state, { payload }) {
      state.progressEnd = payload;
    },
    setClusterProgress(state, { payload }) {
      state.clusterProgress = [...payload];
      state.isLoading = false;
    },
    setClusterLogs(state, { payload }) {
      state.clusterLogs = { ...payload };
      state.isLoading = false;
    },
    hasError(state, { payload }) {
      state.error = { ...payload };
      state.isLoading = false;
    },
  },
});

export function getClusterProgress() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.setProgressEnd(false));
    try {
      dispatch(slice.actions.setClusterProgress(progressRows));
      let index = 0;
      let start = true;
      setInterval(() => {
        if (index < progressRows.length) {
          let date = new Date();
          if (start)
            progressRows[index] = {
              ...progressRows[index],
              startedAt: `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`,
            };
          else {
            progressRows[index] = {
              ...progressRows[index],
              endedAt: `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`,
            };
            index++;
            dispatch(slice.actions.setProgressStep(index));
          }
          dispatch(slice.actions.setClusterProgress(progressRows));
          start = !start;
        } else dispatch(slice.actions.setProgressEnd(true));
      }, 3000);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createClusterLogs(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cluster-logs`,
        {
          id: id,
          logs: progressRows,
        }
      );
      dispatch(slice.actions.setClusterLogs(response?.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
