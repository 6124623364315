import React from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
//import GitHubLogin from "react-github-login";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignUp() {
  const { signUp } = useAuth();

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required("Username is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(8, "Must be at least 8 characters , more is better")
          .max(255)
          .required("Required"),
        confirmPassword: Yup.string().when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          signUp(values.email, values.password, values.username);
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="username"
            label="Full Name"
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={
              (touched.username && errors.username) || "Not used to login"
            }
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="email"
            name="email"
            label="Email address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign up
          </Button>
          <Box
            mt={1}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button component={Link} to="/auth/sign-in" color="primary" flo>
              Sign in
            </Button>
          </Box>

          {/* <Box mt={5} sx={{ display: "flex", justifyContent: "center" }}>
            <GoogleLogin
              clientId="639953303600-jbus3a7idu63a5qrhc8j7m0s6fj6fmfp.apps.googleusercontent.com"
              onSuccess={(response) => {
                signUp(
                  response.profileObj.email,
                  response.googleId,
                  response.profileObj.name
                );
              }}
              onFailure={(error) => {
                console.log(".....", error);
              }}
              buttonText="Sign up with Google"
              cookiePolicy="single_host_origin"
            />
          </Box> */}
          {/* <GitHubLogin
            clientId="ac56fad434a3a3c1561e"
            onSuccess={(response) => {
              console.log(response);
              // signUp(
              //   response.profileObj.email,
              //   response.googleId,
              //   response.profileObj.name
              // );
            }}
          /> */}
        </form>
      )}
    </Formik>
  );
}

export default SignUp;
