import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Avatar,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Typography = styled(MuiTypography)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(4)};
`;

const Project = ({ image, title, description, chip }) => {
  return (
    <Card>
      {image ? <CardMedia image={image} title="Contemplative Reptile" /> : null}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>

        {chip}

        <Typography mb={4} color="textSecondary" component="p">
          {description}
        </Typography>
        {/* to do  edited to be based in props  */}
        <AvatarGroup spacing={20} max={4}>
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-6.png" />
        </AvatarGroup>
      </CardContent>
      <CardActions>
        <Button size="medium" color="primary">
          Details
        </Button>
        <Button size="medium" color="primary">
          Go to
        </Button>
      </CardActions>
    </Card>
  );
};

function Projects() {
  return (
    <React.Fragment>
      <Helmet title="Projects" />
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6} xl={3}>
          <Project
            title="TensorFlow installation"
            description="Follow up in the new requirement,new installation of TensorFlow 2.0 and the latest version of the TensorFlow Hub."
            chip={<Chip label="Finished" color="success" />}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <Project
            title="Redis deployment "
            description="Part of mechanical system enhancement,cache cluster to speed up system performance, streaming engine, and message broker."
            chip={<Chip label="In progress" color="warning" />}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <Project
            title="Install Loadbaloncer"
            description="install loadbalancer for the new shopping Web app, Optimize for API cache & gRPC ."
            chip={<Chip label="Finished" color="success" />}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <Project
            title="Upgrade CRM software"
            description="upgrade CRM software to the latest version, and optimize the system for the latest requirements."
            chip={<Chip label="In progress" color="warning" />}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <Project
            title="Migrate database to Kubernetes"
            description="Migrate database to Kubernetes, and optimize the configuration for optimal operation performance ."
            chip={<Chip label="In progress" color="warning" />}
            image="/static/img/unsplash/K8S_DB.jpg"
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <Project
            title="Models & datasets configuration"
            description="check available models, modules and datasets created by the TensorFlow community."
            chip={<Chip label="On hold" color="error" />}
            image="/static/img/unsplash/unsplash-4.png"
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <Project
            title="Upgrade to latest Maps API"
            description="Upgrade to the latest version of the Google Maps API, optimize system for best performance."
            chip={<Chip label="Finished" color="success" />}
            image="/static/img/unsplash/googlemap.jpg"
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <Project
            title="Security audit, Backend"
            description="conduct security audit for the backend, delivering the reports with possible fixes."
            chip={<Chip label="On hold" color="error" />}
            image="/static/img/unsplash/audit.jpg"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Projects;
