import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  computer: {},
  computers: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: "computer",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setComputer(state, { payload }) {
      state.computer = { ...payload };
      state.isLoading = false;
    },
    setComputers(state, { payload }) {
      state.computers = [...payload];
      state.isLoading = false;
    },
    hasError(state, { payload }) {
      state.error = { ...payload };
      state.isLoading = false;
    },
  },
});

export function createComputer(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/computers`,
        data
      );
      dispatch(slice.actions.setComputer(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getComputerById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/computers/${id}`
      );
      dispatch(slice.actions.setComputer(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllComputers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/computers`
      );
      dispatch(slice.actions.setComputers(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateComputerStatus(id, status) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/computers/${id}`,
        {
          status: status,
        }
      );
      dispatch(slice.actions.setComputer(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
