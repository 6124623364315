import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";

import { Box, CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Auth = ({ children }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", height: "100vh" }}>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        {children}
        <Outlet />
        {/* <Settings /> */}
      </Root>
      <Box
        sx={{
          display: { xs: "none", md: "inline" },
          width: "50%",
          height: "100%",
          backgroundImage: `url("/static/img/unsplash/login-background.webp")`,
          backgroundSize: "100% 100%",
        }}
      ></Box>
    </Box>
  );
};
export default Auth;
