import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
//import DocLayout from "./layouts/Doc";
//import PresentationLayout from "./layouts/Presentation";

// Guards
//import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import ForgetPassword from "./pages/auth/ForgetPassword";
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";

// Page components
// import Blank from "./pages/pages/Blank";
// import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
import Chat from "./pages/pages/Chat";
import MVPlog from "./pages/pages/MVPlog";

// Documentation
// import Welcome from "./pages/docs/Welcome";
// import GettingStarted from "./pages/docs/GettingStarted";
// import Routing from "./pages/docs/Routing";
// import Auth0 from "./pages/docs/auth/Auth0";
// import Cognito from "./pages/docs/auth/Cognito";
// import Firebase from "./pages/docs/auth/Firebase";
// import JWT from "./pages/docs/auth/JWT";
// import Guards from "./pages/docs/Guards";
// import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
// import Deployment from "./pages/docs/Deployment";
// import Theming from "./pages/docs/Theming";
// import APICalls from "./pages/docs/APICalls";
// import Redux from "./pages/docs/Redux";
// import Internationalization from "./pages/docs/Internationalization";
// import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
// import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
// import Support from "./pages/docs/Support";
// Landing
// import Landing from "./pages/presentation/Landing";

// Protected routes
//import ProtectedPage from "./pages/protected/ProtectedPage";
import Marketplace from "./pages/marketplace";
import ComputerCreate from "./pages/computer/ComputerCreate";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

// New pages
const Images = async(() => import("./pages/images"));
const Network = async(() => import("./pages/network"));
const Storage = async(() => import("./pages/storage"));
const Computer = async(() => import("./pages/computer"));
const ComputerProgress = async(() =>
  import("./pages/computer/ComputerProgress")
);
const Registry = async(() => import("./pages/registry"));
const RegistryCreate = async(() => import("./pages/registry/RegistryCreate"));
const RegistryDetails = async(() => import("./pages/registry/RegistryDetails"));
const Kubernetes = async(() => import("./pages/Kubernetes"));
const K8SCreate = async(() => import("./pages/Kubernetes/K8SCreate"));
const K8SProgress = async(() => import("./pages/Kubernetes/K8SProgress"));
const K8SDetails = async(() => import("./pages/Kubernetes/K8SDetails"));

// Icon components

//const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
//const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
//const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
//const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
// const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
// const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
    ],
  },
  {
    path: "kubernetes",
    element: <DashboardLayout />,
    children: [
      {
        path: "", // to be renamed to getstarted
        element: <Kubernetes />,
      },
      {
        path: "create",
        element: <K8SCreate />,
      },
      {
        path: "progress",
        element: <K8SProgress />,
      },
      {
        path: "details",
        element: <K8SDetails />,
      },
    ],
  },
  {
    path: "Storage",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Storage />,
      },
    ],
  },
  {
    path: "network",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Network />,
      },
    ],
  },
  {
    path: "computer",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Computer />,
      },
      {
        path: "create",
        element: <ComputerCreate />,
      },
      {
        path: "progress",
        element: <ComputerProgress />,
      },
    ],
  },
  {
    path: "registry",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Registry />,
      },
      {
        path: "create",
        element: <RegistryCreate />,
      },
      {
        path: "details",
        element: <RegistryDetails />,
      },
    ],
  },
  {
    path: "images",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Images />,
      },
    ],
  },
  {
    path: "marketplace",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Marketplace />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      // {
      //   path: "profile",
      //   element: <Profile />,
      // },
      // {
      //   path: "settings",
      //   element: <Settings />,
      // },
      // {
      //   path: "pricing",
      //   element: <Pricing />,
      // },
      {
        path: "chat",
        element: <Chat />,
      },
      // {
      //   path: "blank",
      //   element: <Blank />,
      // },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "MVPlog",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <MVPlog />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },

  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
