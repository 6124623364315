import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  if (isAuthenticated !== null && isAuthenticated !== undefined) {
    if (
      isAuthenticated === true &&
      (location.pathname === "/auth/sign-in" ||
        location.pathname === "/auth/sign-up")
    ) {
      return <Navigate to="/" />;
    } else if (
      isAuthenticated === false &&
      !location.pathname.includes("/auth")
    ) {
      return <Navigate to="/auth/sign-in" />;
    }
    return <React.Fragment>{children}</React.Fragment>;
  } else return <></>;
}

export default AuthGuard;
