import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  bitnami: [],
  isLoading: false,
  error: null,
};

const search = (data, searchText) => {
  searchText = searchText.toLocaleLowerCase();
  let index = 0;
  while (index < data.length) {
    let categoryCondition = data[index]?.annotations?.category
      ? data[index].annotations.category
          .toLocaleLowerCase()
          .includes(searchText)
      : false;
    if (
      data[index].name.toLocaleLowerCase().includes(searchText) ||
      categoryCondition ||
      data[index].description.toLocaleLowerCase().includes(searchText) ||
      data[index].keywords.join(", ").toLocaleLowerCase().includes(searchText)
    )
      index++;
    else data.splice(index, 1);
  }
  return data;
};

const slice = createSlice({
  name: "bitnami",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setBitnami(state, { payload }) {
      state.bitnami = [...payload];
      state.isLoading = false;
    },
    hasError(state, { payload }) {
      state.error = { ...payload };
      state.isLoading = false;
    },
  },
});

export function getBitnami(searchText = "") {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BITNAMI_API_URL}/bitnami`
      );
      if (searchText) response.data = [...search(response.data, searchText)];
      dispatch(slice.actions.setBitnami(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
