import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import { isValidToken, setSession } from "../utils/jwt";

const axios = require("axios");

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: null,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case "SIGN_IN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case "SIGN_UP":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const urlParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const response = await axios.get(
            `${process.env.REACT_APP_API_STRAPI}/users/me`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          const user = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else if (
          window.location.pathname === "/auth/google" &&
          urlParams.get("id_token") &&
          urlParams.get("access_token")
        ) {
          //Google auth

          const response = await axios.get(
            `${process.env.REACT_APP_API_STRAPI}/auth/google/callback${
              window.location.href.split("/auth/google")[1]
            }`
          );

          const accessToken = response.data.jwt;

          if (accessToken && isValidToken(accessToken)) {
            localStorage.setItem("accessToken", accessToken);
            setSession(accessToken);
            const response = await axios.get(
              `${process.env.REACT_APP_API_STRAPI}/users/me`,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            );
            const user = response.data;

            navigate("/");

            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          }
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        window.localStorage.removeItem("accessToken");
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (email, password) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_STRAPI}/auth/local`,
      {
        identifier: email,
        password: password,
      }
    );

    const accessToken = response.data.jwt;
    const user = response.data.user;

    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, username) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_STRAPI}/auth/local/register`,
      {
        username: username,
        email: email,
        password: password,
      }
    );

    const accessToken = response.data.jwt;
    const user = response.data.user;

    setSession(accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const forgetPassword = (email) => {
    axios
      .post(`${process.env.REACT_APP_API_STRAPI}/auth/forgot-password`, {
        email: email,
      })
      .then((response) => {
        console.log("Your user received an email");
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  };

  const resetPassword = (code, password, passwordConfirmation) => {
    axios
      .post(`${process.env.REACT_APP_API_STRAPI}/auth/reset-password`, {
        code: code,
        password: password,
        passwordConfirmation: passwordConfirmation,
      })
      .then((response) => {
        console.log("Your user's password has been reset.");
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        forgetPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
