import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  formData: {},
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: "formData",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setFormData(state, { payload }) {
      state.formData = { ...payload };
      state.isLoading = false;
    },
    hasError(state, { payload }) {
      state.error = { ...payload };
      state.isLoading = false;
    },
  },
});

export function getClusterFormData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cluster-data`
      );
      dispatch(slice.actions.setFormData(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getComputerFormData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/computer-data`
      );
      dispatch(slice.actions.setFormData(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
