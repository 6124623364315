import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  computerProgress: [],
  computerProgressStep: 0,
  computerProgressEnd: false,
  computerProgressLogs: {},
  computerProgressIsLoading: false,
  computerProgressError: null,
};

const computerProgressRows = [
  {
    task: "OS templates prep",
    resourceId: "Cloud/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "cloud instance initialization",
    resourceId: "VM/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "Storage provisioning",
    resourceId: "Storage/allocation",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "VPC Network",
    resourceId: "Network/Configuration",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "OS SysPrep / Cloud-init",
    resourceId: "VM/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "NAT Gateway & SNAT",
    resourceId: "Cloud/Network ",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "Server DHCP allocation",
    resourceId: "Cloud/Network",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "TCP/IP Protocol prep & test",
    resourceId: "VM/Network",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "Security Hardening ",
    resourceId: "OS/Security ",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "OS update & upgrade",
    resourceId: "VM/OS",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "contentional initiation Cert Configuration",
    resourceId: "OS/Configuration",
    startedAt: null,
    endedAt: null,
  },
  {
    task: "Instance booting & final check",
    resourceId: "OS/Configuration",
    startedAt: null,
    endedAt: null,
  },
];

const slice = createSlice({
  name: "computerProgress",
  initialState,
  reducers: {
    startComputerProgressIsLoadingLoading(state) {
      state.computerProgressIsLoading = true;
    },
    setComputerProgressStep(state, { payload }) {
      state.computerProgressStep = payload;
    },
    setComputerProgressEnd(state, { payload }) {
      state.computerProgressEnd = payload;
    },
    setComputerProgress(state, { payload }) {
      state.computerProgress = [...payload];
      state.computerProgressIsLoading = false;
    },
    setComputerProgressLogs(state, { payload }) {
      state.computerProgressLogs = { ...payload };
      state.computerProgressIsLoading = false;
    },
    hasError(state, { payload }) {
      state.computerProgressError = { ...payload };
      state.computerProgressIsLoading = false;
    },
  },
});

export function getComputerProgress() {
  return async (dispatch) => {
    dispatch(slice.actions.startComputerProgressIsLoadingLoading());
    dispatch(slice.actions.setComputerProgressEnd(false));
    try {
      dispatch(slice.actions.setComputerProgress(computerProgressRows));
      let index = 0;
      let start = true;
      setInterval(() => {
        if (index < computerProgressRows.length) {
          let date = new Date();
          if (start)
            computerProgressRows[index] = {
              ...computerProgressRows[index],
              startedAt: `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`,
            };
          else {
            computerProgressRows[index] = {
              ...computerProgressRows[index],
              endedAt: `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`,
            };
            index++;
            dispatch(slice.actions.setComputerProgressStep(index));
          }
          dispatch(slice.actions.setComputerProgress(computerProgressRows));
          start = !start;
        } else dispatch(slice.actions.setComputerProgressEnd(true));
      }, 3000); // to update it after test
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createComputerProgressLogs(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startComputerProgressIsLoadingLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/computer-logs`,
        {
          id: id,
          logs: computerProgressRows,
        }
      );
      dispatch(slice.actions.setComputerProgressLogs(response?.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
