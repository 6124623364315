import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  statistics: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: "clusterStatistic",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setClusterStatisticis(state, { payload }) {
      state.statistics = [...payload];
      state.isLoading = false;
    },
  },
});

export function getClusterStatistics(id, type) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/charts-${type}`
      );
      dispatch(slice.actions.setClusterStatisticis(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
