import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";

import { spacing } from "@mui/system";

const Chip = styled(MuiChip)`
  height: 20px;
  margin-top: -3px;
  font-weight: bold;
  font-size: 75%;
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

function MVPlog() {
  return (
    <React.Fragment>
      <Helmet title="MVPlog" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h3" gutterBottom display="inline">
            OpenScaler Cloud Computer Technology platform ** MVP Changelog
            Journaling **
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Changelog</Typography>
          </Breadcrumbs>

          <Divider my={6} />
          <Typography color="textSecondary" variant="h5">
            We are trying our best to submit this work in this great day,
            "Algerian Independence day" July 5<sup>th</sup> 2022 the 60
            anniversary of our soul and home beloved country, corresponding to
            the blessing first ten Days of Dhul-Hijjah.
          </Typography>
          <Divider my={6} />
          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v0.0.1" /> – July 05, 2022
              <ul>
                <li>
                  BismiAllah, OpenScaler Cloud Initial Log changes Journaling
                  started
                </li>
                <li>
                  Project submission to Algerian Startup Commit for Labelization
                </li>
              </ul>
            </Typography>

            <Divider my={6} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MVPlog;
