import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";

import { AuthProvider } from "./contexts/JWTContext";
import AuthGuard from "./components/guards/AuthGuard";
import Welcome from "./components/auth/Welcome";

import ReactGA from "react-ga4";
import AlertDialogSlide from "./pages/components/CustomAlerts";

// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

ReactGA.initialize([{ trackingId: "G-W4K5FBB643" }]);

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  });

  useEffect(() => {
    if (window.innerWidth < 1536) setOpenAlert(true);
  }, []);

  const [openAlert, setOpenAlert] = useState(false);

  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | MVP OpenScaler"
        defaultTitle="OpenScaler Cloud Computer MVP "
      />
      <Provider store={store}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>
                    <AuthGuard>
                      <Welcome />
                      <AlertDialogSlide
                        open={openAlert}
                        type="warning"
                        title="** Screen resolution requirement  **"
                        content="The Web platform is not a mobile friendly, for rich and engaging user experience the screen resolution is better to be 1536 x 864 or above."
                        onConfirm={() => {
                          window.localStorage.setItem("nfc-value", true);
                        }}
                        onClose={() => {
                          setOpenAlert(false);
                        }}
                      />
                      {content}
                    </AuthGuard>
                  </AuthProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
