import React from "react";

import { Box, Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

function GoogleAuth() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
      <Button
        variant="contained"
        color="secondary"
        endIcon={<GoogleIcon style={{ fontSize: "20" }} />}
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_API_STRAPI}/connect/google`;
        }}
      >
        Connect with Google
      </Button>
    </Box>
  );
}

export default GoogleAuth;
