import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import useTheme from "../../hooks/useTheme";
import { THEMES } from "../../constants";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

function NavbarDarkMode() {
  const { theme, setTheme } = useTheme();

  return (
    <React.Fragment>
      <Tooltip title={theme !== THEMES.DARK ? "Dark theme" : "Default theme"}>
        <IconButton
          sx={{ ml: 1 }}
          color="inherit"
          onClick={() => {
            setTheme(theme !== THEMES.DARK ? THEMES.DARK : THEMES.GREEN);
          }}
        >
          {theme === THEMES.DARK ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarDarkMode;
