import React from "react";
import styled from "styled-components/macro";

import { Box, Paper, Typography } from "@mui/material";

import SignInComponent from "../../components/auth/SignIn";
import GoogleAuth from "../../components/auth/GoogleAuth";
import { Helmet } from "react-helmet-async";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <img
        src="/static/img/brands/logo.svg"
        width={220}
        height={64}
        alt="logo-opensacler"
        style={{ marginBottom: 32 }}
      />
      <Wrapper>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          gutterBottom
          mb={8}
        >
          Sign in to your account
        </Typography>
        <SignInComponent />
        <GoogleAuth />
      </Wrapper>
      <Box sx={{ position: "absolute", bottom: 16 }}>
        <Typography mx={4}>
          © 2022 - OpenScaler Cloud Computer Technology Platform ** MVP **
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default SignIn;
