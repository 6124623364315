import React, { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";

function Welcome() {
  useEffect(() => {
    if (
      !window.localStorage.getItem("nfv-value") &&
      !window.location.pathname.includes("auth")
    )
      document.getElementById("welcome-page").style.display = "flex";
  }, []);

  return (
    <Box
      id="welcome-page"
      sx={{
        width: "100%",
        height: "100vh",
        position: "absolute",
        background:
          "linear-gradient(90deg, rgba(1,0,13,1) 0%, rgba(3,2,8,0.8687850140056023) 47%, rgba(4,4,4,0.6811099439775911) 68%, rgba(0,255,46,0.258140756302521) 95%)",
        left: 0,
        top: 0,
        zIndex: 2000,
        opacity: 0.85,
        display: "none",
        color: "#ffffff",
        justifyContent: "start",
        paddingY: 8,
        paddingX: {
          xs: 8,
          xl: 24,
        },
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <CloseIcon
        sx={{
          position: "absolute",
          right: 20,
          top: 20,
          cursor: "pointer",
          color: "#ffffff",
        }}
        title="Close"
        fontSize="large"
        //    style={{ fontSize: 50 }}
        onClick={() => {
          document.getElementById("welcome-page").style.display = "none";
          window.localStorage.setItem("nfv-value", true);
        }}
      />
      <Box
        sx={{
          maxHeight: "100%",
          maxWidth: {
            xs: "100%",
            xl: "70%",
          },
        }}
      >
        <Box sm={{ ml: { xs: 0, lg: 16 } }}>
          <img
            alt="OpenScaler Logo"
            src="/static/img/brands/logo.svg"
            width={"80%"}
          />
        </Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: 28, md: 32, lg: 36 },
          }}
          fontWeight={600}
          mb={10}
          mt={10}
        >
          Welcome to OpenScaler Cloud Computing Platform
        </Typography>
        <Box fontSize={32} ml={10}>
          <Typography variant="subtitle1" fontSize={20} my={6}>
            OpenScaler Cloud Computing Technology Platform, in its current stage
            of development “MVP”, provided as a “DEMO Mode” with Mocking data
            (Fake data), made available for the purpose of demonstrating to our
            (innovator & early adopter) a simulation of real use cases.
          </Typography>
          <Typography variant="subtitle1" fontSize={20} my={6}>
            OpenScaler Cloud in its current state is missing an important piece,
            a physical Data Center infrastructure to serve the services exposed
            through the WebApp Platform.
          </Typography>
          <Typography variant="subtitle1" fontSize={18} mt={10}>
            <Box sx={{ fontWeight: "bold" }}>Disclaimer :</Box>* &nbsp;The
            WebApp UI is tracking and collecting data
            <b> “** Anonymously **”</b> about the platform performance and users
            behavior, for the sole purpose of improve user Experience.
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize={16}
            my={5}
            mt={10}
            display="flex"
            alignItems={{ xl: "center" }}
          >
            *&nbsp;Just a last one, the Web platform is not a mobile friendly,
            for rich and engaging user experience the screen resolution is
            better to be 1680 x 1050 or above.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Welcome;
