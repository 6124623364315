import React from "react";
import { styled as style } from "@mui/material/styles";
import styled from "styled-components/macro";

import { Box, Badge, Grid, Avatar, Typography, Stack } from "@mui/material";

import useAuth from "../../hooks/useAuth";

const StyledBadge = style(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(1.5)}
    ${(props) => props.theme.spacing(4)};
  border-radius: 0 0 ${(props) => props.theme.spacing(1)}; */
  color: ${(props) => props.theme.sidebar.footer.color};
  padding: 0px 0px 1px 1px;
  border-top: 0.5px solid #e0e0e066;
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  padding: 2px 2px 2px 0;
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooter = ({ ...rest }) => {
  const { user } = useAuth();

  return (
    <Footer {...rest}>
      <Grid container py={2.6} px={1}>
        <Grid item>
          <Stack direction="row" spacing={2} mr={3}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar alt="Remy Sharp" src="/static/img/avatars/avatar-1.jpg" />
            </StyledBadge>
          </Stack>
        </Grid>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            {user && <FooterText variant="body2">{user.username} </FooterText>}
            <FooterSubText variant="caption">Cloud Architect </FooterSubText>
          </Box>
        </Box>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
