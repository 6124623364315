import { Chat, Support } from "@mui/icons-material";

import {
  BookOpen,
  Briefcase,
  List,
  Sliders,
  Package,
  Share2,
  Anchor,
  Image,
  ShoppingCart,
  Database,
  Server,
} from "react-feather";

const pagesSection = [
  {
    href: "/dashboard/default",
    icon: Sliders,
    title: "Dashboard",
  },
  {
    href: "/kubernetes",
    icon: Anchor,
    title: "Kubernetes",
  },
  {
    href: "/computer",
    icon: Server,
    title: "Computer",
  },

  {
    href: "/network",
    icon: Share2,
    title: "Network",
  },

  {
    href: "/storage",
    icon: Database,
    title: "Storage",
  },
  {
    href: "/registry",
    icon: Package,
    title: "Registry",
  },
  {
    href: "/images",
    icon: Image,
    title: "Images",
  },
  {
    href: "/marketplace",
    icon: ShoppingCart,
    title: "Market place",
  },
  {
    href: "/projects",
    icon: Briefcase,
    title: "Projects",
    badge: "8",
  },
];

const docsSection = [
  {
    href: "/tasks",
    icon: Support,
    title: "Cases",
    badge: "17",
  },
  {
    icon: Chat,
    href: "/pages/chat",
    title: "Chat",
  },

  {
    href: "/documentation/welcome",
    icon: BookOpen,
    title: "Doc & KB",
  },
  {
    href: "/MVPlog",
    icon: List,
    title: "MVP",
    badge: "log",
  },
];

const navItems = [
  {
    title: "Projects",
    pages: pagesSection,
  },

  {
    title: "Supports",
    pages: docsSection,
  },
];

export default navItems;
