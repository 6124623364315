import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  TextField as MuiTextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Fab,
  Slider,
  Chip,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getComputerFormData } from "../../redux/slices/formData";
import { createComputer } from "../../redux/slices/computer";
import AlertDialogSlide from "../components/CustomAlerts";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const validationSchema = Yup.object().shape({
  computerName: Yup.string()
    .matches(
      /^([a-zA-Z0-9_]){1,63}$/i,
      "The name must be 1 to 63 characters in length and can contain letters, Arabics characters, digit and hyphens (_)."
    )
    .required("Computer Name is required"),
  description: Yup.string(),
  region: Yup.object().required("Region is required"),
  availabilityZones: Yup.object().required("Availability Zones is required"),
  computingResources: Yup.object().required("Computing resources is required"),
  os: Yup.object().required("OS is required"),
});

export function OSSelect(props) {
  const [selected, setSelected] = useState(null);

  return (
    <FormControl fullWidth>
      <Select
        size="small"
        onChange={(e) => {
          setSelected(+e.target.value);
          props.onChange(+e.target.value);
        }}
        displayEmpty
        notchedOutline={true}
        value={selected || 0}
        variant="outlined"
        sx={{
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        }}
      >
        {props.data?.map((version, indexV) => (
          <MenuItem key={indexV} value={indexV}>
            {version}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function OS(props) {
  const [selected, setSelected] = useState(null);
  const [osData, setOsData] = useState({
    name: null,
    version: null,
  });

  return (
    <>
      <Grid container item spacing={6} overflow="auto">
        {props?.osList?.map((os, index) => (
          <Grid item key={index}>
            <div
              style={{
                cursor: "pointer",
                width: 164,
                borderRadius: 6,
                backgroundColor:
                  index !== selected ? "transparent" : "#66757C5511",
              }}
              onClick={() => {
                setSelected(index);
                if (osData.name !== os.name) {
                  setOsData({
                    version: os.version
                      ? os.versions[os.version]
                      : os.versions[0],
                    name: os.name,
                  });
                  props.onChange({
                    version: os.version
                      ? os.versions[os.version]
                      : os.versions[0],
                    name: os.name,
                  });
                }
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: 4,
                  paddingTop: 12,
                  border:
                    index === selected
                      ? "2px solid #71D0F288"
                      : "1px solid #66757C55",
                  borderBottom: "none",
                  borderRadius: 6,
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <img
                  src={os.image}
                  alt="computer"
                  loading="lazy"
                  height={72}
                  width={72}
                />
                <Typography variant="h6" my={2}>
                  {os.name}
                </Typography>
              </div>
              <OSSelect
                data={os.versions}
                onChange={(value) => {
                  setOsData({
                    name: os.name,
                    version: os.versions[value],
                  });
                  props.onChange({
                    name: os.name,
                    version: os.versions[value],
                  });
                }}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function Disk(props) {
  const [item, setItem] = useState({
    type: null,
    storage: 10,
  });

  return (
    <Grid container spacing={6} mt={-2} mx={0}>
      <Grid item xs={6} sm={2} lg={1} mt={1.6}>
        <Typography ml={2} variant="subtitle2" gutterBottom>
          Disk #{props?.index}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FormControl fullWidth>
          <InputLabel id="disks-label" size="small">
            Disk
          </InputLabel>
          <Select
            size="small"
            name="disks"
            labelId="disks-label"
            label="Disk"
            displayEmpty
            variant="outlined"
            error={props?.error}
            onChange={(e) => {
              setItem((prevItem) => ({
                ...prevItem,
                type: e.target.value,
              }));
              props.onChange({
                ...item,
                type: e.target.value,
              });
            }}
          >
            <MenuItem value={"nvme"}>NVME</MenuItem>
            <MenuItem value={"ssd"}>SSD</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Slider
          aria-label="disk"
          size="small"
          value={item.storage}
          min={10}
          max={999}
          onChange={(e) => {
            setItem((prevItem) => ({
              ...prevItem,
              storage: +e.target.value,
            }));
            props.onChange({
              ...item,
              storage: +e.target.value,
            });
          }}
        />
        <Typography variant="body1" ml={2}>
          {item.storage}Gb
        </Typography>
      </Grid>
      <Grid item mt={1}>
        <Fab
          size="small"
          color="secondary"
          aria-label="Add"
          onClick={(value) => props.onUnmount()}
        >
          <RemoveIcon />
        </Fab>
      </Grid>
    </Grid>
  );
}

function NavHeader(props) {
  return (
    <Grid
      item
      sx={{
        borderBottom: "1px solid #66757C55",
        cursor: "pointer",
        mt: 2,
        mb: 6,
        display: {
          xs: "inline",
          sm: "flex",
        },
        position: "relative",
      }}
    >
      {props.options.elements.map((item, index) => {
        return (
          <Typography
            key={index}
            variant="subtitle1"
            sx={{ marginRight: 4, paddingBottom: 1.5, paddingX: 2 }}
            borderBottom={index === 0 ? "3px solid #71D0F288" : null}
            color={index !== 0 ? "#66757C55" : ""}
            title={index !== 0 ? "Coming soon" : ""}
          >
            <b>{item.name}</b>
          </Typography>
        );
      })}
      <Chip
        label="Coming soon"
        color="success"
        size="small"
        sx={{ opacity: 0.7 }}
      />
    </Grid>
  );
}

function Plan(props) {
  return (
    <Box overflow="auto">
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box>
          <Box
            sx={{ background: "#71D0F21E", py: 0.5, px: 4, borderRadius: 1 }}
          >
            <Typography align="center" variant="subtitle2">
              <b>Shared CPU</b>
            </Typography>
          </Box>
          <Box
            sx={{
              border: "2px solid #71D0F288",
              background: "#66757C5511",
              mt: 2,
              py: 4,
              px: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
              cursor: "pointer",
              width: 176,
            }}
          >
            <Typography variant="h6">Basic</Typography>
          </Box>
        </Box>
        <Box sx={{ ml: 4 }} color="#66757C55" title="Coming soon">
          <Box
            sx={{
              background: "#71D0F21E",
              py: 0.5,
              px: 4,
              position: "relative",
              borderRadius: 1,
            }}
          >
            <Typography
              align="center"
              variant="subtitle2"
              mr={4}
              color="#66757C99"
            >
              <b>DEDICATED CPU</b>
            </Typography>
            <Chip
              label="Coming soon"
              color="success"
              size="small"
              sx={{ position: "absolute", right: 0, top: 0, opacity: 0.7 }}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                border: "2px solid #66757C5511",
                mt: 2,
                py: 4,
                px: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                cursor: "pointer",
                width: 176,
              }}
            >
              <Typography variant="h6">AI-Optimized </Typography>
            </Box>
            <Box
              sx={{
                border: "2px solid #66757C5511",
                mt: 2,
                ml: 3,
                py: 4,
                px: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                cursor: "pointer",
                width: 176,
              }}
            >
              <Typography variant="h6">CPU-Optimized</Typography>
            </Box>
            <Box
              sx={{
                border: "2px solid #66757C5511",
                mt: 2,
                ml: 3,
                py: 4,
                px: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                cursor: "pointer",
                width: 176,
              }}
            >
              <Typography variant="h6">Memory-Optimized</Typography>
            </Box>
            <Box
              sx={{
                border: "2px solid #66757C5511",
                mt: 2,
                ml: 3,
                py: 4,
                px: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                cursor: "pointer",
                width: 176,
              }}
            >
              <Typography variant="h6">Storage-Optimized</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider my={2} />
      <Box sx={{ display: "flex", width: "100%" }}>
        {props?.data?.map((cr, index) => (
          <Box
            key={index}
            sx={{
              border: `${
                cr === props.value
                  ? "2px solid #71D0F288"
                  : "1px solid #66757C55"
              }`,

              background: `${
                cr === props.value ? "#66757C5511" : "transparent"
              }`,
              mt: 2,
              mr: 4,
              borderRadius: 1,
              cursor: "pointer",
              width: 176,
            }}
            onClick={() => {
              props.onChange(cr);
            }}
          >
            <Box py={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  px: 2,
                }}
              >
                <Typography variant="subtitle2">$ </Typography>
                <Typography variant="h3" ml={1}>
                  {" "}
                  {cr.pricePerMonth}
                </Typography>
                <Typography variant="subtitle2"> /mo</Typography>
              </Box>
              <Typography align="center" variant="subtitle2" mt={-1}>
                $ {cr.pricePerHour} /hour{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                borderTop: `1px solid #66757C55`,
                p: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="subtitle2">{cr.type}</Typography>
              <Typography variant="subtitle2">{cr.cpu}</Typography>
              <Typography variant="subtitle2">
                <b>
                  {cr.ram.value} {cr.ram.unity}
                </b>{" "}
                RAM
              </Typography>
              <Typography variant="subtitle2">
                <b>
                  {cr.storage.value} {cr.storage.unity}
                </b>{" "}
                Storage
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
export function CreateComputer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openAlert, setOpenAlert] = useState(false);

  const { formData, isLoading, error } = useSelector((state) => state.formData);

  useEffect(() => {
    dispatch(getComputerFormData());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      computerName: "",
      description: "",
      region: null,
      availabilityZones: null,
      computingResources: null,
      os: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setOpenAlert(true);
    },
  });

  const onChangeHandler = (name, value) => {
    formik.setFieldValue(name, value);
    formik.setFieldTouched(name, true, false);
  };

  const maxDisks = 5;
  const [diskKeys, setdiskKeys] = useState([1]);
  const [currentDisks, setcurrentDisks] = useState([]);

  const updateDisks = (item, index) => {
    let array = [...currentDisks];
    array[index] = item;
    setcurrentDisks(array);
  };

  const removeDisks = (index) => {
    let keys = [...diskKeys];
    keys.splice(index, 1);
    setdiskKeys([...keys]);

    let array = [...currentDisks];
    array.splice(index, 1);
    setcurrentDisks([...array]);
  };

  const [totalPrices, setTotalPrices] = useState({
    hour: "XX",
    month: "XX",
  });

  useEffect(() => {
    const updateTotal = () => {
      let prices = {
        hour: 0,
        month: 0,
      };
      for (let disk of currentDisks) {
        if (disk.type) {
          prices.hour +=
            formData.diskPricing[disk.type].pricePerHour * disk.storage;
          prices.month +=
            formData.diskPricing[disk.type].pricePerMonth * disk.storage;
        }
      }
      prices.hour = Number(prices.hour.toFixed(2));
      prices.month = Number(prices.month.toFixed(2));
      setTotalPrices(prices);
    };
    updateTotal();
  }, [currentDisks, formData.diskPricing]);

  return (
    !isLoading &&
    !error && (
      <form onSubmit={formik.handleSubmit}>
        <AlertDialogSlide
          open={openAlert}
          type="confirm"
          title="** Simulation of Computer Instance creation **"
          content="Next step, the computer instance will be provisioned as per your configuration. feel free to move to another task, the provisioning keeps executing in the background."
          onConfirm={() => {
            dispatch(
              createComputer({
                ...formik.values,
                disks: currentDisks,
                status: "Pending",
              })
            ).then((result) => {
              window.localStorage.setItem("nfc-value", true);
              navigate(`/computer/progress?id=${result?.id}`);
            });
          }}
          onClose={() => {
            setOpenAlert(false);
          }}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} lg={10}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Basic information
                </Typography>
                <Grid container item spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      size="small"
                      name="computerName"
                      label="Computer Name"
                      value={formik.values.computerName}
                      error={Boolean(
                        formik.touched.computerName &&
                          formik.errors.computerName
                      )}
                      helperText={
                        formik.touched.computerName &&
                        formik.errors.computerName
                      }
                      onChange={(e) => {
                        onChangeHandler("computerName", e.target.value);
                      }}
                      variant="outlined"
                      my={2}
                      fullWidth
                    />
                    <FormControl fullWidth sx={{ my: 2 }}>
                      <InputLabel id="region-label" size="small">
                        Region
                      </InputLabel>
                      <Select
                        size="small"
                        name="region"
                        labelId="region-label"
                        label="Region"
                        value={formik.values.region}
                        error={Boolean(
                          formik.touched.region && formik.errors.region
                        )}
                        onChange={(e) => {
                          onChangeHandler("region", e.target.value);
                          onChangeHandler("availabilityZones", null);
                        }}
                        displayEmpty
                        variant="outlined"
                      >
                        <MenuItem value="" disabled>
                          Select Region
                        </MenuItem>
                        {formData?.regions?.map((r, index) => (
                          <MenuItem key={index} value={r}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: 20.8,
                                  height: 13,
                                  backgroundImage: `url("${r?.flagUrl}")`,
                                  backgroundSize: "100% 100%",
                                  marginRight: 4,
                                }}
                              ></div>
                              {r?.name}
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      size="small"
                      name="computerName"
                      label="Description"
                      value={formik.values.description}
                      error={Boolean(
                        formik.touched.description && formik.errors.description
                      )}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                      onChange={(e) => {
                        onChangeHandler("description", e.target.value);
                      }}
                      variant="outlined"
                      my={2}
                      fullWidth
                    />
                    <FormControl fullWidth sx={{ my: 2 }}>
                      <InputLabel id="availabilityZones-label" size="small">
                        Availability Zones
                      </InputLabel>
                      <Select
                        size="small"
                        name="availabilityZones"
                        labelId="availabilityZones-label"
                        label="Availability Zones"
                        value={formik.values.availabilityZones}
                        // eslint-disable-next-line prettier/prettier
                        error={Boolean(
                          formik.touched.availabilityZones &&
                            formik.errors.availabilityZones
                        )}
                        disabled={!formik.touched.region}
                        onChange={(e) => {
                          onChangeHandler("availabilityZones", e.target.value);
                        }}
                        displayEmpty
                        variant="outlined"
                      >
                        <MenuItem value="" disabled>
                          Select Zone
                        </MenuItem>
                        {formik.values.region?.zones?.map((az, index) => (
                          <MenuItem key={index} value={az}>
                            {az?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Typography variant="h6" gutterBottom mb={4}>
                  Computing resources
                </Typography>
                <Plan
                  data={formData?.computingResources}
                  value={formik.values.computingResources}
                  // eslint-disable-next-line prettier/prettier
                  error={Boolean(
                    formik.touched.computingResources &&
                      formik.errors.computingResources
                  )}
                  onChange={(value) => {
                    onChangeHandler("computingResources", value);
                  }}
                />
              </CardContent>
              <CardContent>
                <Typography variant="h6" gutterBottom mb={4}>
                  Operating systems
                </Typography>
                <NavHeader
                  options={{
                    elements: [
                      {
                        value: "distributions",
                        name: "Distributions",
                      },
                      {
                        value: "container-distributions",
                        name: "Container distributions",
                      },
                      {
                        value: "marketplace",
                        name: "Marketplace",
                      },
                      {
                        value: "custom-images",
                        name: "Custom images",
                      },
                    ],
                  }}
                />
                <OS
                  osList={formData?.os}
                  onChange={(value) => {
                    onChangeHandler("os", value);
                  }}
                />
              </CardContent>
              <CardContent>
                <Typography variant="h6" gutterBottom mb={4}>
                  Disks
                </Typography>
                <Grid container item spacing={6}>
                  <Grid item md={12}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Fab
                        mx={2}
                        size="small"
                        color="secondary"
                        aria-label="Add"
                        onClick={(e) => {
                          setdiskKeys((prevItem) => [
                            ...prevItem,
                            new Date().getTime(),
                          ]);
                          setcurrentDisks((prevItem) => [
                            ...prevItem,
                            {
                              sharedVolumes: null,
                              capacity: 0,
                              pricePerHour: 0,
                              pricePerMonth: 0,
                              encrypt: false,
                            },
                          ]);
                        }}
                        disabled={currentDisks.length >= 5}
                      >
                        <AddIcon />
                      </Fab>
                      <Typography mx={2} variant="subtitle2" gutterBottom>
                        Add disk
                      </Typography>
                    </div>
                  </Grid>
                  {currentDisks?.map((volume, index) => {
                    return (
                      <Disk
                        key={diskKeys[index]}
                        index={index}
                        disks={formData?.disks}
                        error={Boolean(!volume?.type)}
                        onChange={(value) => updateDisks(value, index)}
                        onUnmount={() => removeDisks(index)}
                      />
                    );
                  })}
                  <Grid item md={1}></Grid>
                  <Grid item md={10} mt={-5}>
                    {currentDisks?.length > 0 && (
                      <Typography variant="body2" ml={2}>
                        You have selected {currentDisks?.length} disks you can
                        add{" "}
                        {maxDisks - currentDisks?.length >= 0
                          ? maxDisks - currentDisks?.length
                          : 0}{" "}
                        more
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Card mb={6}>
              <CardContent>
                <Grid item my={4}>
                  <Typography
                    variant="h6"
                    color={"secondary"}
                    textAlign={"center"}
                    gutterBottom
                  >
                    Summary
                  </Typography>
                  <Divider variant="middle" my={3} />
                  <Typography variant="subtitle1" gutterBottom mt={3}>
                    Resources
                  </Typography>
                  {formik.values?.computingResources && (
                    <>
                      <Typography variant="subtitle2" ml={1} gutterBottom>
                        {formik.values?.computingResources?.type}
                      </Typography>
                      <Typography variant="body2" ml={1} gutterBottom>
                        {`${formik.values?.computingResources?.cpu} ${formik.values?.computingResources?.ram.value} ${formik.values?.computingResources?.ram.unity} RAM ${formik.values?.computingResources?.storage.value} ${formik.values?.computingResources?.storage.unity} storage`}
                      </Typography>
                    </>
                  )}
                  {formik.values?.availabilityZones && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 20.8,
                          height: 13,
                          backgroundImage: `url("${formik.values?.region?.flagUrl}")`,
                          backgroundSize: "100% 100%",
                          marginRight: 4,
                        }}
                      ></div>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        textAlign={"right"}
                        mt={1.25}
                      >
                        {formik.values?.availabilityZones?.name}
                      </Typography>
                    </div>
                  )}
                  <>
                    <Typography
                      variant="body1"
                      color={"secondary"}
                      gutterBottom
                      mt={3}
                    >
                      ${" "}
                      {Number(
                        totalPrices.month +
                          formik.values?.computingResources?.pricePerMonth || 0
                      ).toFixed(2)}{" "}
                      / Month
                    </Typography>
                    <Typography
                      variant="body1"
                      color={"secondary"}
                      gutterBottom
                    >
                      ${" "}
                      {Number(
                        totalPrices.hour +
                          formik.values?.computingResources?.pricePerHour || 0
                      ).toFixed(2)}{" "}
                      / Hours
                    </Typography>
                  </>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    Create Computer
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    )
  );
}

function ComputerCreate() {
  return (
    <React.Fragment>
      <Helmet title="Computer Creation" />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateComputer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ComputerCreate;
