import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  cluster: {},
  clusters: [],
  logs: {},
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: "cluster",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingLogs(state) {
      state.isLoading = true;
    },
    setCluster(state, { payload }) {
      state.cluster = { ...payload };
      state.isLoading = false;
    },
    setClusters(state, { payload }) {
      state.clusters = [...payload];
      state.isLoading = false;
    },
    setLogs(state, { payload }) {
      state.logs = { ...payload };
      state.isLoading = false;
    },
    hasError(state, { payload }) {
      state.error = { ...payload };
      state.isLoading = false;
    },
  },
});

export function createCluster(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/clusters`,
        data
      );
      dispatch(slice.actions.setCluster(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateClusterStatus(id, status) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/clusters/${id}`,
        {
          status: status,
        }
      );
      dispatch(slice.actions.setCluster(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClusterById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/clusters/${id}`
      );
      dispatch(slice.actions.setCluster(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClusterLogs(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cluster-logs/${id}`
      );
      dispatch(slice.actions.setLogs(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllClusters() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/clusters`
      );
      dispatch(slice.actions.setClusters(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
