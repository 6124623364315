import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  registry: {},
  registries: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: "registry",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setRegistry(state, { payload }) {
      state.registry = { ...payload };
      state.isLoading = false;
    },
    setRegistries(state, { payload }) {
      state.registries = [...payload];
      state.isLoading = false;
    },
    hasError(state, { payload }) {
      state.error = { ...payload };
      state.isLoading = false;
    },
  },
});

export function createRegistry(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/registries`,
        data
      );
      dispatch(slice.actions.setRegistry(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRegistryById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/registries/${id}`
      );
      dispatch(slice.actions.setRegistry(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllRegistries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/registries`
      );
      dispatch(slice.actions.setRegistries(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
