import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Fab,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 100%;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatMain = styled(Grid)``;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: calc(85vh - 94px);
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 44px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 5px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

function ChatAlert() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        background:
          //    "radial-gradient(circle, rgba(2,9,9,0.40940126050420167) 16%, rgba(183,255,103,1) 100%)",
          "linear-gradient(90deg, rgba(1,0,13,1) 0%, rgba(3,2,8,0.8687850140056023) 47%, rgba(4,4,4,0.6811099439775911) 68%, rgba(0,255,46,0.258140756302521) 95%)",
        left: 0,
        top: 0,
        zIndex: 2000,
        opacity: 0.85,
        color: "#ffffff",
        justifyContent: "start",
        paddingY: 8,
        paddingX: {
          xs: 8,
          xl: 24,
        },
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          maxHeight: "100%",
          maxWidth: "100%",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: 28, md: 32, lg: 36 },
          }}
          fontWeight={600}
          mb={10}
          mt={10}
        >
          Feature under development
        </Typography>
        <Box fontSize={32} ml={10} mr={80}>
          <Typography variant="subtitle1" fontSize={20} my={6}>
            Chat and Communication are an important part of the Cloud platform
            they are intended to lower the gap between the support team and the
            platform users.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function ChatMessageComponent({
  name,
  message,
  time,
  avatar,
  position = "left",
}) {
  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <ChatMessageAvatar alt="OpenScaler Support Team" src={avatar} />
        <ChatMessageBubble highlighted={position === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {name}
            </ChatMessageBubbleName>
          </Box>
          <Typography variant="body2">{message}</Typography>
        </ChatMessageBubble>
        <ChatMessageTime variant="body2">{time}</ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function ChatWindow() {
  return (
    <ChatContainer container component={Card} position="relative">
      <ChatAlert />

      <ChatSidebar item xs={12} md={4} lg={3}>
        <Grid item xs={12}>
          <Box p={2}>
            <TextField label="Search contacts" variant="outlined" fullWidth />
          </Box>
        </Grid>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <Online
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar
                  alt="OpenScaler Support Team"
                  src="/static/img/avatars/avatar-1.jpg"
                />
              </Online>
            </ListItemIcon>
            <ListItemText
              primary="OpenScaler Support Team"
              secondary="Ticket dispatcher"
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Online
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar alt="Khaled " src="/static/img/avatars/avatar-2.jpg" />
              </Online>
            </ListItemIcon>
            <ListItemText
              primary="Khalid"
              secondary="Follow up in the IO performance issue"
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Avatar alt="Oussama " src="/static/img/avatars/avatar-3.jpg" />
            </ListItemIcon>
            <ListItemText
              primary="Oussama"
              secondary="Welcome to OpenScaler Cloud 👋"
            />
          </ListItem>
        </List>
      </ChatSidebar>
      <ChatMain item xs={12} md={8} lg={9}>
        <ChatMessages>
          <ChatMessageComponent
            name="Khaled Ab"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="Hi Team , we are facing performance problem with one of our VPC instances. "
            time="20 minutes ago"
            position="left"
          />
          <ChatMessageComponent
            name="OpenScaler Support Team"
            avatar="/static/img/avatars/avatar-1.jpg"
            message="Welcome Mr Guest, it's openScaler Support team. We are glad to have you here. please give us some time to dispatch the tacket to the right team member.
            meanwhile could you please full up the tacket questions "
            time="12 minutes ago"
            position="right"
          />
          <ChatMessageComponent
            name="Khaled Ab"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="Done"
            time="8 minutes ago"
            position="left"
          />
          <ChatMessageComponent
            name="OpenScaler Support Team"
            avatar="/static/img/avatars/avatar-1.jpg"
            message="Hi there , my name is Oussama from SRE team I will be following up with you in this case , please could you share with me the VPC log file. there is a step by step guide in KB00012 link attached      👍"
            time="5 minutes ago"
            position="right"
          />
          <ChatMessageComponent
            name="Khaled Ab"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="sure I will collected and attached the log file to the case"
            time="3 minutes ago"
            position="left"
          />
        </ChatMessages>
        <Divider />

        <ChatInput container>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField variant="outlined" label="Type your message" fullWidth />
          </Grid>
          <Grid item>
            <Fab color="secondary" size="large">
              <SendIcon />
            </Fab>
            <Fab color="primary" aria-label="add" size="medium">
              <AttachFileIcon />
            </Fab>
          </Grid>
        </ChatInput>
      </ChatMain>
    </ChatContainer>
  );
}

function Chat() {
  return (
    <React.Fragment>
      <Helmet title="Chats" />

      <ChatWindow />
    </React.Fragment>
  );
}

export default Chat;
